import * as React from 'react'
import { Box } from 'theme-ui'

interface LayoutMainProps {
  className?: string
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => (
  <Box
    as="main"
    className={className}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}
  >
    {children}
  </Box>
)

export default LayoutMain
