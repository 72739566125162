import * as React from 'react';
import { Box, BoxProps } from 'theme-ui';

import { widths } from '../styles/variables';
import { getEmSize } from '../styles/mixins';

interface ContainerProps extends BoxProps {
	className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className, ...props }) => (
	<Box
		className={className}
		ml="auto"
		mr="auto"
		{...props}
		sx={{
			position: 'relative',
			width: 'auto',
			maxWidth: `${getEmSize(widths.lg)}em`,
			...props.sx
		}}
	>
		{children}
	</Box>
);

export default Container;
