import * as React from 'react';
import { Box } from 'theme-ui';

import { dimensions } from '../styles/variables';

interface PageProps {
  className?: string;
}

const Page: React.FC<PageProps> = ({ children, className }) => (
  <Box
    sx={{
      display: 'block',
      flex: 1,
      position: 'relative',
      padding: `${dimensions.containerPadding}rem`,
      marginBottom: '3rem'
    }}
    className={className}
  >
    {children}
  </Box>
);

export default Page;
